export enum LoginType {
  PRIVATE = 'private',
  BUSINESS = 'business',
  UNDEFINED = 'undefined',
}

export const BanksMap: { [key: number]: string } = {
  1: 'Sydbank A/S',
  8: 'Nordfynsbank A/S',
  9: 'Aktieselskabet Skjern Bank',
  13: 'Djurslands Bank A/S',
  15: 'Kreditbanken',
  24: 'Ringkjøbing Landbobank',
  38: 'S-E-Banken',
  42: 'Sparekassen Sjælland-Fyn',
  47: 'Sydbank A/S (Tyskland)',
  51: 'Jyske Bank A/S',
  53: 'Jyske Bank A/S (Hamburg)',
  54: 'Jyske Bank A/S',
  81: 'BRF Kredit',
  95: 'Jyske Bank A/S',
  100: 'Bankdata',
  300: 'BANKDATA',
};



export enum Nav {
  CPR = 'cpr',
  LOGON = 'logon',
  REDIRECT = 'redirect',
  ORDERS = 'orders',
}
