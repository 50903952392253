import { Component, Inject } from '@angular/core';
import { Translations, TRANSLATIONS_TOKEN } from '../translations/translations';
import { EnvironmentService } from '../../../environments/environment.service';
import { OAuthService } from 'src/app/services/oauth/oauth.service';
import { Router } from '@angular/router';
import {BanksMap, Nav} from '../../utils/enums';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  constructor(
    @Inject(TRANSLATIONS_TOKEN) public text: Translations,
    private environmentService: EnvironmentService,
    private oAuthService: OAuthService,
    private router: Router,
    private translateService: TranslateService
  ) {}

  public get isLoggedIn() {
    return this.oAuthService.isLoggedIn;
  }

  public get logoUrl(): string {
    const bankNumber = this.environmentService.bankNumber;
    if (bankNumber == null) {
      return '';
    }
    const bankNumberPaddedString = bankNumber.toString().padStart(3, '0');
    return `assets/logos/${bankNumberPaddedString}.svg`;
  }

  public onLogout() {
    this.oAuthService.logout();
    this.router.navigate([Nav.LOGON]);
  }

  getBankIconAltText() {
    return BanksMap[this.environmentService.bankNumber]  + " " + this.translateService.instant(this.text.toolbar.logo.accessibility.alt);
  }
}
