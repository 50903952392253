<div class="toolbarContainer">
  <div class="logoContainer">
    <kirby-item>
      <img class="logo"  [alt]="getBankIconAltText()" role="img" src="{{ logoUrl }}" />
    </kirby-item>
  </div>
  <div class="logoutContainer">
    <kirby-item *ngIf="isLoggedIn">
      <button slot="end" kirby-button attentionLevel="4" (click)="onLogout()">
        <kirby-icon name="log-out" role="presentation"></kirby-icon>
        <span>{{ text.common.logout | translate }}</span>
      </button>
    </kirby-item>
  </div>
</div>
