<kirby-item>
  <div>

    <!-- This is the desktop version of the order's signatureDeadline -->
    <kirby-item class="showLargerThanMobileOnly">
      <kirby-label>
        <h2 class="kirby-text-bold" [attr.data-orderId]="order.orderId">{{ order.title }}</h2>
      </kirby-label>
      <kirby-label slot="end">
        <data detail slot="end">{{ deadlineText }} d. {{ signatureDeadline | date: 'mediumDate' }}
          kl. {{ signatureDeadline | date: 'shortTime' }}</data>
      </kirby-label>
    </kirby-item>

    <!-- This is the mobile version of the order's signatureDeadline -->
    <kirby-item class="showMobileOnly">
      <kirby-label>
        <h2 class="kirby-text-bold" [attr.data-orderId]="order.orderId">{{ order.title }}</h2>
        <data detail slot="end">{{ deadlineText }} d. {{ signatureDeadline | date: 'mediumDate' }}
          kl. {{ signatureDeadline | date: 'shortTime' }}</data>
      </kirby-label>
    </kirby-item>

    <!-- Container for the order's description -->
    <kirby-item *ngIf="order.description">
      <div class="semi-light-container rounded-padding description-container">
        <button kirby-button aria-label="Opmærksomhed påkrævet" class="transparent">
          <kirby-icon name="flag" style="padding-right: 0.6em">
          </kirby-icon>
        </button>
        <span> {{ order.description }} </span>
      </div>
    </kirby-item>

    <!-- Container for the order's signer -->
    <kirby-item>
      <span style="white-space: normal"><i>Du {{this.order.orderSigner.name}} modtager nedenstående {{documentText()}}
        til underskrift {{getCvrText()}}</i></span>
    </kirby-item>

    <!-- Container for the order's documents -->
    <kirby-item>
      <div class="documentList">
        <div *ngFor="let document of order.orderDocuments; let index = index">
          <div class="documentRow">
            <div *ngIf="!document.signatureRequired" style="display: flex; padding-left: 20px; margin-bottom: 5px">
              <button kirby-button aria-label="Bilags ikon" class="transparent" style="height: 24px">
                <kirby-icon name="attach" slot="start" themeColor="tertiary" size="xs"></kirby-icon>
                <i>{{document.title}}</i>
              </button>
            </div>
            <ng-container *ngIf="document.signatureRequired">
              <span
                [ngStyle]="{ 'font-weight': document.signatureRequired ? 'bold' : 'normal', 'font-style': document.signatureRequired ? 'normal' : 'italic'}">{{ getTrueIndex(index) }}
                ) {{ document.title }}</span>
            </ng-container>
          </div>
          <div *ngFor="let att of document.attachments">
            <div style="display: flex; padding-left: 20px; margin-bottom: 5px">
              <button kirby-button aria-label="Bilags ikon" class="transparent" style="height: 24px">
                <kirby-icon name="attach" slot="start" themeColor="tertiary" size="xs"></kirby-icon>
                <i>{{att.title}}</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </kirby-item>

    <!-- Container for the order's signature button explainer text -->
    <kirby-item>
      <span
        style="font-size: 12px; line-height: 19px">Jeg er indforstået med, at jeg ved klik på knappen "{{ text.orders.button.created | translate }}
        " skal forholde
        mig til {{documentText(true)}} (og eventuelle bilag, der er markeret med
        <button kirby-button aria-label="Bilags ikon" class="transparent">
          <kirby-icon themeColor="tertiary" name="attach" size="xs"></kirby-icon>
        </button>
        ), og at jeg kan vælge enten at afvise eller underskrive
        <ng-container *ngIf="signableDocumentsCount() > 1">
          hele dokumentpakken og samtlige {{documentText(false, 'dokumentet')}}.
        </ng-container>
        <ng-container *ngIf="signableDocumentsCount() <= 1">
          {{documentText(false, 'dokumentet')}}.
        </ng-container>
      </span>
    </kirby-item>

    <!-- Container for the order's signature button -->
    <kirby-item>
      <div slot="end">
        <button kirby-button (click)="onSignOrder()" [disabled]="!isAwaitingSigning()" [name]="getButtonText()">
          {{ getButtonText() }}
        </button>
        <kirby-label style="font-size: 10px; text-align: center"><i>{{getButtonTitle()}} </i></kirby-label>
      </div>
    </kirby-item>
  </div>
</kirby-item>
